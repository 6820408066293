import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import axios from "axios";
import { apiURL, webURL } from "../global/apiURL";
import YouTubePopup from "../components/YouTubeIframe";

const FBAdsSubmit = () => {

  const [referCode,setReferCode] = useState('')
  const [selectedImage, setSelectedImage] = useState(null);
  const [totalTask, setTotalTask] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const userId = localStorage.getItem("id");

  const loadData = () => {
    let config = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    axios
      .get(`${apiURL}/api/task/total/${userId}/facebook`, config)
      .then((res) => {
        setTotalTask(res.data.total_task);
      })
      .catch((error) => {
        console.log(error);
      });
  };

      
  const loadRData = ()=>{
    axios
      .get(`${apiURL}/api/auth/getreferer/${userId}`)
      .then((res) => {
        if (res.data) {
          setReferCode(`${webURL}/register?referCode=${res.data.referal_code}`)
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    loadData();
    loadRData()
  }, []);

  const onSubmit = ()=>{
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("task_type", "facebook");
    formData.append("photo", selectedImage);
    let config = {
      headers: { 
        "content-type": "multipart/form-data",
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .post(`${apiURL}/api/task/add`, formData, config)
      .then((res) => {
        if (res.data.id) {
          alert(res.data.message);
        } else {
           alert(res.data.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  if(isOpen){
    return(
      <YouTubePopup isOpen={isOpen} setIsOpen={setIsOpen} />
    )
  }
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />
        <div className="layout-page">
          <Header />
          {/* <div className="col-10 mt-4" style={{display:'inline-block',marginLeft:'7%'}}>
              <div className="card">
                  <iframe 
                    height={420}
                    style={{borderRadius:'10px'}}
                    src="https://www.youtube.com/embed/tgbNymZ7vqY"
                  ></iframe>
              </div>
              </div> */}
          <div className="content-wrapper">
            <div className="container mt-5">
              <h2>Steps To Do Tasks</h2>
              <ol className="steps">
                {/* <li>Open the provided link.</li>
                <li>Like the post.</li>
                <li>Share the post on your timeline.</li>
                <li>Take a screenshot of the shared post.</li>
                <li>
                  Submit the screenshot in the designed area below the post.
                </li> */}
                <p>
                  Video Watching Work Available <br/>

                  Watch 10 Advertisements & Get 1000 Rupees<br/>

                  Just 10 Advertisements Videos Dekhny K Apko 1000 Rupees Milty Hain<br/>

                  Payments Easy Paisa, Jazz Cash, Ya Kisi Bhi Bank Account Ma Ly Skty Hain<br/>

                  Join Krny K Liay Ap Neechy Diay Gaye Link Py Click Kr K Information Dekh Skty Hain, Video Website Py Di Gayi Ha Ap Link Py Click Kr K Website Visit Kr Skty Hain<br/>

                  Customer Reviews And Payment Proofs Ap Humari Website Py Dekh Skty Hain, Daily Update Hotay Hain<br/>

                  Ap "trustpilot & scamadviser" Py Bhi Humari Ratings Check Kr Skty Hain<br/>

                  WEBSITE LINK : {referCode} <br/>

                  Have A Nice Day ❤
                </p>
              </ol>

              <div className="row">
                <label>Total Screenshot Submitted Today: {totalTask} / 100</label>
                <br />
                <input
                  type="file"
                  className="form-control"
                  onChange={(event) => {
                    //console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                  }}
                  aria-describedby="defaultFormControlHelp"
                />
              </div>
              <br />
              <button onClick={onSubmit} type="button" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  );
};

export default FBAdsSubmit;
