import React, { useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import Header from "../components/Header";
// import Footer from "../components/Footer";
import axios from "axios";
import { apiURL } from "../global/apiURL";

const Dashboard = () => {
  const [loader, setLoader] = useState(true);
  const [stats, setStats] = useState(null);
  const userId = localStorage.getItem("id");

  const loadData = () => {
    setLoader(true);
    let config = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    axios
      .get(`${apiURL}/api/dashboard/stats/${userId}`, config)
      .then((res) => {
        setStats(res.data);
        console.log(res.data)
      })
      .catch((error) => {
        console.log(error);
      });
    setLoader(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  if(loader){
    return(
      <span className="text-muted fw-light">
        Loading tasks For you please wait...
      </span>
    )
  }

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />
        <div className="layout-page">
          <Header />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row">
                <div className="col-lg-6 col-md-4 order-1">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-6 mb-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-title d-flex align-items-start justify-content-between">
                            <div className="avatar flex-shrink-0">
                              <img
                                src="assets/img/icons/unicons/cc-success.png"
                                alt="chart success"
                                className="rounded"
                              />
                            </div>
                          </div>
                          <span className="fw-semibold d-block mb-1">Total Referals</span>
                          <h3 className="card-title text-nowrap mb-2">{stats?.referal_count}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-6 mb-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-title d-flex align-items-start justify-content-between">
                            <div className="avatar flex-shrink-0">
                              <img
                                src="assets/img/icons/unicons/wallet-info.png"
                                alt="Credit Card"
                                className="rounded"
                              />
                            </div>
                          </div>
                          <span>Tasks Completed</span>
                          <h3 className="card-title text-nowrap mb-2">{stats?.task_count}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8 col-lg-6 order-3 order-md-2">
                  <div className="row">
                    <div className="col-6 mb-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-title d-flex align-items-start justify-content-between">
                            <div className="avatar flex-shrink-0">
                              <img
                                src="assets/img/icons/unicons/paypal.png"
                                alt="Credit Card"
                                className="rounded"
                              />
                            </div>
                          </div>
                          <span className="d-block mb-1">Total Withdrawals</span>
                          <h3 className="card-title text-nowrap mb-2">${parseFloat(stats?.total_withdraw?.total_withdrawal).toFixed(2)}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 mb-4">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-title d-flex align-items-start justify-content-between">
                            <div className="avatar flex-shrink-0">
                              <img
                                src="assets/img/icons/unicons/cc-primary.png"
                                alt="Credit Card"
                                className="rounded"
                              />
                            </div>
                          </div>
                          <span className="fw-semibold d-block mb-1">
                          Available Balance
                          </span>
                          <h3 className="card-title mb-2">${parseFloat(stats?.account_balance).toFixed(2)}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-12 order-2 order-md-3 order-lg-2 mb-4">
              {/* <div className="card">
                  <iframe 
                    height={420}
                    style={{borderRadius:'10px'}}
                    src="https://www.youtube.com/embed/tgbNymZ7vqY"
                  ></iframe>
              </div> */}
              </div>
            </div>

            {/* <Footer/> */}

            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  );
};

export default Dashboard;
