import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import ReactPlayer from "react-player";
import { useLocation, useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import axios from "axios";
import { apiURL } from "../global/apiURL";

const YTAdsTask = () => {
  const location = useLocation()
  const navigate = useNavigate();
  const onReward = ()=>{
    let user_id = localStorage.getItem("id");
    let ytData = {
      video_id: location.state.video_id,
      user_id: user_id,
    };
    let config = {
      headers: { Authorization: localStorage.getItem("token")}
    };
    axios
      .post(`${apiURL}/api/ytads/add`, ytData, config)
      .then((res) => {
        if (res.data.id) {
          alert(res.data.message);
          navigate('/youtube')
        } else {
           alert(res.data.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const time = new Date();
  time.setSeconds(time.getSeconds() + 60);
  const {
    seconds,
    minutes,
    start,
    pause,
    resume,
  } = useTimer({ expiryTimestamp:time, autoStart:false ,onExpire: onReward});

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />
        <div className="layout-page">
          <Header />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4">
                <span className="text-muted fw-light">Youtube Task</span>
              </h4>

              <div className="container mt-5">
              <div class="d-grid gap-2 col-lg-4 mx-auto pb-3">
                <button type="button" class="btn btn-primary btn-lg">
                  <span class="tf-icons bx bx-pie-chart-alt"></span>&nbsp;
                  {`${minutes} : ${seconds}`}
                </button>
              </div>
                <div className="row" style={{ height: 320 }}>
                  <ReactPlayer
                    url={location.state.videoLink}
                    controls
                    width="100%"
                    height="100%"
                    onStart={start}
                    onPause={pause}
                    onPlay={resume}
                    // playing={true}
                  />
                </div>
              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>

      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  );
};

export default YTAdsTask;
