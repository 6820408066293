import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import Toast from "../components/Toast";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { apiURL } from "../global/apiURL";
import YouTubePopup from "../components/YouTubeIframe";

const Withdraw = () => {
  const [showToast, setToast] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const { register, handleSubmit, reset, watch, control } = useForm();
  const selectedPaymentMethod = watch('payment_method');

  const onSubmit = async (data) => {
    let user_id = localStorage.getItem("id");
    let withdrawData = {
      payment_method: data.bankAccountName ? data.payment_method + " " + data.bankAccountName : data.payment_method ,
      amount: data.amount,
      owner_name: data.owner_name,
      account_number: data.account_number,
      user_id: user_id,
    };
    console.log(withdrawData)
    let config = {
      headers: { Authorization: localStorage.getItem("token")}
    };
    axios
      .post(`${apiURL}/api/withdraw/add`, withdrawData, config)
      .then((res) => {
        reset();
        if (!res.data.referCheck) {
          alert(res.data.message);
        } else {
          setToast(true)
          console.log(res.data.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if(isOpen){
    return(
      <YouTubePopup isOpen={isOpen} setIsOpen={setIsOpen} />
    )
  }

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />

        <div className="layout-page">
          <Header />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4">
                <span className="text-muted fw-light">Get Your Money</span>
              </h4>
              <div className="container">
                <div className="wrapper">
                  <div className="withdraw-container">
                    <div className="header">
                      <h5>
                        Please Select your preferred payment method and enter
                        the withdrawal amount below.
                      </h5>
                    </div>

                    <div className="row">
                      <div className="card mb-4">
                        <h5 className="card-header">Payment Methods</h5>
                        <div className="card-body">
                          <div>
                            <label
                              for="defaultFormControlInput"
                              className="form-label"
                            >
                              Select An Option:
                            </label>
                            <div className="mb-3">
                            <Controller          
                              name="payment_method"
                              control={control}
                              defaultValue="easypaisa"
                              render={({ field }) => (
                                <select
                                  className="form-select"
                                  {...field}
                                  id="payment_method"
                                  name="payment_method"
                                  aria-label="Default select example"
                                >
                                  <option value="easypaisa">Easy Paisa</option>
                                  <option value="jazzcash">Jazz Cash</option>
                                  <option value="upaisa">U Paisa</option>
                                  <option value="hbl_connect">HBL Konnect</option>
                                  <option value="sadapay">Sada Pay</option>
                                  <option value="nayapay">Naya Pay</option>
                                  <option value="bank">Bank Transfer</option>
                                </select>
                                )}
                              />
                              <br />
                              <div>
                              {selectedPaymentMethod === 'bank' && (
                              <div className="mb-3">
                                <label
                                  for="defaultFormControlInput"
                                  className="form-label"
                                >
                                  Bank Account Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register("bankAccountName")}
                                  id="bankAccountName"
                                  name="bankAccountName"
                                  aria-describedby="defaultFormControlHelp"
                                />
                                </div>
                              )}
                              <div className="mb-3">
                                <span>$</span>
                                <label
                                  for="defaultFormControlInput"
                                  className="form-label"
                                >
                                  Amount
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  {...register("amount")}
                                  id="amount"
                                  name="amount"
                                  aria-describedby="defaultFormControlHelp"
                                />
                                </div>
                                <div className="mb-3">
                                <label
                                  for="defaultFormControlInput"
                                  className="form-label"
                                >
                                  Account Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register("account_number")}
                                  id="account_number"
                                  name="account_number"
                                  aria-describedby="defaultFormControlHelp"
                                />
                                </div>
                                <div>
                                <label
                                  for="defaultFormControlInput"
                                  className="form-label"
                                >
                                  Account Owner Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register("owner_name")}
                                  id="owner_name"
                                  name="owner_name"
                                  aria-describedby="defaultFormControlHelp"
                                />
                                </div>
                                <Toast
                                  setAlert={setToast}
                                  showToast={showToast}
                                  title={"Unable to give withdraw"}
                                  body={
                                    "You must have atleat 3 refers to apply withdraw"
                                  }
                                />
                                <div className="card-body">
                                  <div className="row mt-3">
                                    <div className="d-grid gap-2 col-lg-6 mx-auto">
                                      <button
                                        className="btn btn-primary btn-lg"
                                        type="button"
                                        onClick={handleSubmit(onSubmit)}
                                      >
                                        Withdraw
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>

      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  );
};

export default Withdraw;
