import React, { useEffect } from "react";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import { useForm } from "react-hook-form";
import axios from "axios";
import { apiURL } from "../global/apiURL";

const InviteFriends = () => {
  const { register, handleSubmit, reset } = useForm();
  const userId = localStorage.getItem("id");

  const loadData = ()=>{
    axios
      .get(`${apiURL}/api/auth/getuser/${userId}`)
      .then((res) => {
        if (res.data) {
          reset({ 
            username: res.data.username, 
            email: res.data.email,
            mobileno: res.data.mobile_no,
            whatsapp: res.data.whatsapp_no
          });
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    loadData()
  }, []);

  const onSubmit = async (data) => {
    let config = {
      headers: { Authorization: localStorage.getItem("token")}
    };
    let userData = {
      id: userId,
      username: data.username, 
      email: data.email,
      mobileno: data.mobileno,
      whatsapp: data.whatsapp
    };

    axios
      .post(`${apiURL}/api/auth/update`, userData, config)
      .then((res) => {
          loadData()
          alert(res.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />

        <div className="layout-page">
          <Header />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4">
                <span className="text-muted fw-light">Update Profile!</span>
              </h4>

              <div className="row">
                <div className="card mb-4 custom-card">
                  <form className="card-body" onSubmit={handleSubmit(onSubmit)} >
                    <label for="username" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      {...register("username")}
                      name="username"
                      placeholder="Enter your username"
                    />

                    <div className="mb-3">
                      <label for="newEmail" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("email")}
                        name="email"
                        placeholder="Enter your email"
                      />
                    </div>

                    <div className="mb-3">
                      <label for="newMobileNo" className="form-label">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("mobileno")}
                        name="mobileno"
                        placeholder="Enter your mobile number"
                      />
                    </div>

                    {/* <div>
                      <label
                        for="defaultFormControlInput"
                        className="form-label"
                      >
                        Select Payment Method
                      </label>
                      <div className="mb-3">
                        <select
                          className="form-select"
                          {...register("paymethod")}
                          name="paymethod"
                          aria-label="Default select"
                        >
                          <option value="easypaisa">EasyPaisa</option>
                          <option value="jazzcash">JazzCash</option>
                          <option value="bank">Bank Transfer</option>
                        </select>
                      </div>
                    </div> */}

                    <div className="mb-3">
                      <label for="newWhatsapp" className="form-label">
                        Whatsapp Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("whatsapp")}
                        name="whatsapp"
                        placeholder="Enter your whatsapp number"
                      />
                    </div>

                    {/* <div className="mb-3 form-password-toggle">
                      <label className="form-label" for="newPassword">
                        Password
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          type="password"
                          {...register("password")}
                          className="form-control"
                          name="password"
                          placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                          aria-describedby="password"
                        />
                      </div>
                    </div> */}
                    <br />
                    <button className="btn btn-primary d-grid" type="submit">
                      Update Profile
                    </button>
                  </form>
                </div>
              </div>

              <Footer />

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>
      </div>
    </div>
  );
};

export default InviteFriends;
