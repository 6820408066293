import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SideBar from '../components/SideBar'
import axios from "axios";
import { apiURL, webURL } from "../global/apiURL";

const UpdateProfile = () => {

  // const [showReferal,setShowReferal] = useState(false)
  const [referCode,setReferCode] = useState('')
  // const [rcode,setRCode] = useState('')

  const userId = localStorage.getItem("id");

  const loadData = ()=>{
    axios
      .get(`${apiURL}/api/auth/getreferer/${userId}`)
      .then((res) => {
        if (res.data) {
          setReferCode(`${webURL}/register?referCode=${res.data.referal_code}`)
          // setShowReferal(res.data.referStatus==1 ? false : true )
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // const saveReferer = ()=>{
  //   axios
  //     .get(`${apiURL}/api/auth/savereferer/${userId}/${rcode}`)
  //     .then((res) => {
  //         alert(res.data.message);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   // console.log(userId + ' : ' + rcode)
  // }

  useEffect(() => {
    loadData()
  }, []);

  const handleClipboard = async () => {
    console.log(referCode)
    await navigator.clipboard.writeText(referCode)
    alert("Copied to Clipboard")
  }

  return (
    <div className="layout-wrapper layout-content-navbar">
    <div className="layout-container">
      <SideBar/>
      <div className="layout-page">
        <Header/>
        <div className="content-wrapper">

          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Invitation To Friends</span></h4>
            <div className="container">
              <div className="wrapper">

                <div className="withdraw-container">
                  <div className="header">
                    <h5>Send Invitations To Your Friends By Using Your Referral Code</h5>
                  </div>
                  <div className="row">
                    <div className="card mb-4">
                      <h5 className="card-header">Your Code For Inviting Referrals</h5>
                      <div className="card-body">
                          <label for="defaultFormControlInput" className="form-label"></label>
                          <input type="text" className="form-control" id="defaultFormControlInput"
                            value={referCode} aria-describedby="defaultFormControlHelp" /><br/>
                          <button type="button" onClick={handleClipboard} className="mb-2 btn btn-primary">Copy Code</button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <Footer/>
          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </div>

    <div className="layout-overlay layout-menu-toggle"></div>
  </div>
)
}

export default UpdateProfile


// { showReferal ? (
//   <div className=" align-items-center">
//     <div className="row">
//       <div className="card mb-4">
//         <div className="col-md-4"></div>
//           <div className="card-body">
//             <div className="referral-box">
//               <form action="#" method="post" id="referral-form">
//                 <label for="referralCode">Enter Your Referral Code:</label>
//                 <input type="Value" className="form-control" id="defaultFormControlInput"
//                    value={rcode} onChange={(v)=>setRCode(v.target.value)} aria-describedby="defaultFormControlHelp" />
//                 <br/>
//               </form>
//               <button type="button" className="btn btn-primary" onClick={saveReferer} >Submit</button>
//             </div>
//           </div>
//       </div>
//     </div>
//   </div> ) : null
// }