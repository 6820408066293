import Home from './pages/Home';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import Dashboard from './pages/Dashboard';
import YoutubeTask from './pages/YoutubeTask';
import Transaction from './pages/Transaction';
import InviteFriends from './pages/InviteFriends';
import Withdraw from './pages/Withdraw';
import UpdateProfile from './pages/UpdateProfile';
import FBAdsSubmit from './pages/FBAdsSubmit';
import YTAdsTask from './pages/YTAdsTask';
import TaskTracker from './pages/TaskTracker';
import InstagramAds from './pages/InstagramAds';
import WhatsappAds from './pages/WhatsappAds';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home/>,
    },
    {
      path: "/login",
      element: <Login/>,
    },
    {
      path: "/register",
      element: <Register/>,
    },
    {
      path: "/forgot",
      element: <ForgotPassword/>,
    },
    {
      path: "/dashboard",
      element: <Dashboard/>,
    },
    {
      path: "/youtube",
      element: <YoutubeTask/>,
    },
    {
      path: "/facebook",
      element: <FBAdsSubmit/>,
    },
    {
      path: "/transaction",
      element: <Transaction/>,
    },
    {
      path: "/invite",
      element: <InviteFriends/>,
    },
    {
      path: "/withdraw",
      element: <Withdraw/>,
    },
    {
      path: "/update",
      element: <UpdateProfile/>,
    },
    {
      path: "/fbadsubmit",
      element: <FBAdsSubmit/>,
    },
    {
      path: "/instagram",
      element: <InstagramAds/>,
    },
    {
      path: "/whatsapp",
      element: <WhatsappAds/>,
    },
    {
      path: "/tyadsubmit",
      element: <YTAdsTask/>,
    },
    {
      path: "/tasktracker",
      element: <TaskTracker/>,
    },
  ]);
  return (
    <RouterProvider router={router} />
  );
}

export default App;
