import React from "react";

const Home = () => {
    return (
      <iframe
      className="iframe"
        id="home"
        src="/home/index.html"
      ></iframe>
  );
};

export default Home;
