import React from "react";

const Footer = () => {
  return (
    <footer className="content-footer footer bg-footer-theme">
      <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
        <div className="mb-2 mb-md-0">
          ©<script>document.write(new Date().getFullYear());</script> made with
          by
          <a
            href="#"
            target="_blank"
            className="footer-link fw-bolder"
          >
            &nbsp; Bin Amir Technologies
          </a>
        </div>
        <div>
          <a href="#" className="footer-link me-4" target="_blank">
            Home
          </a>
          <a href="#" target="_blank" className="footer-link me-4">
            Withdraw
          </a>

          <a href="#" target="_blank" className="footer-link me-4">
            Tasks
          </a>

          <a href="#" target="_blank" className="footer-link me-4">
            Earnings
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
