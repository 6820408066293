import React from "react";

const Toast = ({setAlert,showToast,title,body}) => {
  return (
    <div
      className={`bs-toast toast fade ${showToast ? 'show' : ''}  bg-primary`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <i className="bx bx-bell me-2"></i>
        <div className="me-auto fw-semibold">{title}</div>
        <button
          type="button"
          className="btn-close"
          //data-bs-dismiss="toast"
          aria-label="Close"
          onClick={()=>setAlert(false)}
        ></button>
      </div>
      <div className="toast-body">
        {body}
      </div>
    </div>
  );
};

export default Toast;
