import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SideBar from '../components/SideBar';
import axios from 'axios';
import { apiURL } from "../global/apiURL";

const Transaction = () => {
  const [loader, setLoader] = useState(false);
  const [transactionList, setTransactionList] = useState(null);
  const userId = localStorage.getItem("id");

  const loadData = () => {
    setLoader(true);
    let config = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    axios
      .get(`${apiURL}/api/withdraw/get/${userId}`, config)
      .then((res) => {
        setTransactionList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoader(false);
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar/>
        <div className="layout-page">
        <Header/>
          <div className="content-wrapper">

            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Transactions</span></h4>

              <div className="card">  
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Transaction Id</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                    {loader && (
                    <span className="text-muted fw-light">
                      Loading tasks For you please wait...
                    </span>
                  )}
                  {!loader &&
                    transactionList &&
                    transactionList.map((value, index) => (
                      <tr key={index.toString()}>
                        <td><i className="text-danger me-3"></i> <strong>{value.id}</strong></td>
                        <td><i className="text-danger me-3"></i> <strong>{value.amount}</strong></td>
                        <td>{value.createdAt}</td>
                        <td><span className={`badge bg-label-${value.withdraw_status=='pending'?'primary':value.withdraw_status=='approved'?'success':'danger'} me-1`}>{value.withdraw_status}</span></td>
                      </tr>
                    ))
                  }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            
            <Footer/>
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
    </div>  )
}
// warning info success
export default Transaction