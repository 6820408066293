import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import axios from "axios";
import { apiURL } from "../global/apiURL";

const WhatsappAds = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [totalTask, setTotalTask] = useState(0);

  const userId = localStorage.getItem("id");

  const loadData = () => {
    let config = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    axios
      .get(`${apiURL}/api/task/total/${userId}/whatsapp`, config)
      .then((res) => {
        setTotalTask(res.data.total_task);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onSubmit = () => {
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("task_type", "whatsapp");
    formData.append("photo", selectedImage);
    let config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .post(`${apiURL}/api/task/add`, formData, config)
      .then((res) => {
        if (res.data.id) {
          alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />
        <div className="layout-page">
          <Header />
          <div
            className="col-10 mt-4"
            style={{ display: "inline-block", marginLeft: "7%" }}
          >
            {/* <div className="card">
              <iframe
                height={420}
                style={{ borderRadius: "10px" }}
                src="https://www.youtube.com/embed/tgbNymZ7vqY"
              ></iframe>
            </div> */}
          </div>
          <div className="content-wrapper">
            <div className="container mt-5">
              <h2>Steps To Do Tasks</h2>

              <ol className="steps">
                <li>Open the provided link.</li>
                <li>Like the post.</li>
                <li>Share the post on your timeline.</li>
                <li>Take a screenshot of the shared post.</li>
                <li>
                  Submit the screenshot in the designed area below the post.
                </li>
              </ol>

              <div className="row">
                <label>Total Screenshot Submitted Today: {totalTask} / 100</label>
                <br />
                <input
                  type="file"
                  className="form-control"
                  onChange={(event) => {
                    //console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                  }}
                  aria-describedby="defaultFormControlHelp"
                />
              </div>
              <br />
              <button
                onClick={onSubmit}
                type="button"
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  );
};

export default WhatsappAds;
