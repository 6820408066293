import React, { useEffect, useState } from "react";

const YouTubePopup = ({ isOpen, setIsOpen }) => {
  // useEffect(() => {
  //     // Function to show the popup on page load
  //     const showPopup = () => {
  //       setIsOpen(true);
  //     };

  //     // Call the showPopup function when the component mounts
  //     showPopup();

  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Style the popup as desired */}
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
        }}
      >
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          onClick={handleClose}
        >
          Close
        </button>
        {/* YouTube iframe */}
        <iframe
          title="YouTube Video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/7RcXVgUfaYU"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default YouTubePopup;
