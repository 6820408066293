import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import axios from "axios";
import { apiURL } from "../global/apiURL";
import { Link } from "react-router-dom";

const YoutubeTask = () => {
  const [loader, setLoader] = useState(false);
  const [adsList, setAdsList] = useState(null);
  const userId = localStorage.getItem("id");

  const loadData = () => {
    setLoader(true);
    let config = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    axios
      .get(`${apiURL}/api/ytads/get/${userId}`, config)
      .then((res) => {
        setAdsList(res.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoader(false);
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />
        <div className="layout-page">
          <Header />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4">
                <span className="text-muted fw-light">Tasks</span>
              </h4>

              <div className="container mt-5">
                <div className="row">
                  {loader && (
                    <span className="text-muted fw-light">
                      Loading tasks For you please wait...
                    </span>
                  )}
                  {!loader &&
                    adsList &&
                    adsList.map((value, index) => (
                      <div className="col-md-4 mb-3" key={index.toString()}>
                        <Link
                          to={"/tyadsubmit"}
                          state={{ videoLink: value.video_link, video_id: value.id }}
                          className="card-link"
                        >
                          <div className="card bg-danger text-white">
                            <div className="card-header">Youtube</div>
                            <div className="card-body">
                              <h5 className="card-title text-white">
                                Task Number {value.id}
                              </h5>
                              {/* <p className="card-text">$0.2</p> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>

            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>

      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  );
};

export default YoutubeTask;
