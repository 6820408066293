import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiURL } from "../global/apiURL";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Header = () => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const userId = localStorage.getItem("id");

  useEffect(()=>{
    loadData()
  },[])

  const loadData = () => {
    axios
      .get(`${apiURL}/api/auth/getuser/${userId}`)
      .then((res) => {
        if (res.data) {
          setUsername(res.data.username);
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleLogout(){
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    navigate("/login");
  }

  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a className="nav-item nav-link px-0 me-xl-4">
          <i className="bx bx-menu bx-sm"></i>
        </a>
      </div>

      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <div className="navbar-nav align-items-center">
          <div className="nav-item d-flex align-items-center">
            <span className="fw-bold">Welcome Back</span>
          </div>
        </div>

        <ul className="navbar-nav flex-row align-items-center ms-auto">
          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <a
              className="nav-link dropdown-toggle hide-arrow"
              href="javascript:void(0);"
              data-bs-toggle="dropdown"
            >
              <div className="avatar avatar-online">
                <img
                  src="assets/img/avatars/1.png"
                  alt
                  className="w-px-40 h-auto rounded-circle"
                />
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <a className="dropdown-item" href="#">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-online">
                        <img
                          src="assets/img/avatars/1.png"
                          alt
                          className="w-px-40 h-auto rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <span className="fw-semibold d-block">{username}</span>
                      <small className="text-muted">User</small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              {/* <li>
                <a className="dropdown-item" href="#">
                  <i className="bx bx-user me-2"></i>
                  <span className="align-middle">My Profile</span>
                </a>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li> */}
              <li>
                <a className="dropdown-item" onClick={handleLogout}>
                  <i className="bx bx-power-off me-2"></i>
                  <span className="align-middle">Log Out</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <Helmet>
        <script src="/assets/js/main.js"></script>
      </Helmet>
    </nav>
  );
};

export default Header;
